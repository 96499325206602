import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubscriptionItem, SubscriptionResponse } from '../model/subscription';
import { SubscriptionShortModelData } from "desiren-core-lib/lib/types/subscriptions";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
  private gateUrl = environment.subscriptionsUrl;
  private paymentUrl = environment.paymentsUrl;
  public creatorSubscriptionsSubject = new BehaviorSubject<SubscriptionResponse>(null);
  creatorSubscriptionsSubject$ = this.creatorSubscriptionsSubject.asObservable();
  public userSubscriptionSubject = new BehaviorSubject<SubscriptionResponse>(null);
  userSubscriptionSubject$ = this.userSubscriptionSubject.asObservable();

  constructor(private http: HttpClient) { }

  public getCreatorSubscriptions(id: string): Observable<SubscriptionResponse[]> {
    return this.http.get<any>(`${this.gateUrl}/${id}`, { withCredentials: true })
      .pipe(
        tap((subscription) => this.creatorSubscriptionsSubject.next(subscription[0])),
        take(1)
      );
  }

  public deleteCreatorSubscription(body: { id: string }): Observable<any> {
    return this.http.delete<any>(`${this.gateUrl}/manage/archive`, { body, withCredentials: true }).pipe(take(1));
  }

  public patchSubscriptionName(id: string, name: string): Observable<any> {
    return this.http.put<any>(`${this.gateUrl}/manage/${id}`, { name: name }, { withCredentials: true }).pipe(take(1));
  }

  public createCreatorSubscription(subscriptionBody: SubscriptionItem): Observable<any> {
    return this.http.post<any>(`${this.gateUrl}/manage/add`, subscriptionBody, { withCredentials: true });
  }

  public switchToFreeSubscription(body: {name: string}): Observable<any> {
    return this.http.post<any>(`${this.gateUrl}/manage/switch-to-free-subscription`, body, { withCredentials: true });
  }

  //

  public getFreeSubscription(body: { ownerId: string, subscriptionId: string }): Observable<any> {
    return this.http.post<any>(`${this.paymentUrl}/purchases/subscriptions/free`, body, { withCredentials: true });
  }

  public buySubscription(body: { targetId: string, cardId: string }): Observable<any> {
    return this.http.post<any>(`${this.paymentUrl}/purchases/subscriptions/buy`, body, { withCredentials: true });
  }

  public trialSubscription(body: { targetId: string, cardId: string }): Observable<any> {
    return this.http.post<any>(`${this.paymentUrl}/purchases/subscriptions/trial`, body, { withCredentials: true });
  }

  public cancelSubscription(body: { toUserId: string, cancellationReason: string }): Observable<any> {
    return this.http.put<any>(`${this.paymentUrl}/purchases/subscriptions/cancel`, body, { withCredentials: true });
  }

  public getSubscriptionToUserId(toUserId: string): Observable<any> {
    return this.http.get<any>(`${this.paymentUrl}/purchases/subscriptions/${toUserId}`, { withCredentials: true })
    .pipe(
      tap((subscription) => this.userSubscriptionSubject.next(subscription)),
      take(1)
    );;
  }

  public getSubscribes(id: string): Observable<any> {
    return this.http.get<any>(`${this.paymentUrl}/purchases/subscriptions/user/${id}/subscribes`, { withCredentials: true });
  }

  public getSubscribers(id: string): Observable<any> {
    return this.http.get<any>(`${this.paymentUrl}/purchases/subscriptions/user/${id}/subscribers`, { withCredentials: true });
  }

}
